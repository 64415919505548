.search-seo-text-v1 {
	padding: 60px;
	background: var(--color-white);
	border-radius: 3px;

	@media (max-width: 767px) {
		padding: 20px;
	}


}