.search-page-layout-v1 {
	background-color: var(--color-grey-light);
	margin-top: var(--page-margin-top);

	@media (max-width: 992px) {
		margin-top: 75px;
	}

	@media all and (min-width: 993px) and (max-width: 1199px) {
		margin-top: 60px;
	}

	.checkbox label:after {
		top: 2px;
	}
}